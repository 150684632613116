<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="580px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">
                Agregar
                <v-icon right>mdi-plus-box-outline</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="tipo === 5">
                        <v-text-field 
                            v-model="inventario.emisionCo2"
                            label="Emisiones CO2"
                            :error-messages="emisionesErrors"
                            @input="$v.inventario.emisionCo2.$touch()"
                            @blur="$v.inventario.emisionCo2.$touch()"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-text-field 
                            v-model="certificacion.organismoValidacion"
                            label="Organismo de Validación"
                            :error-messages="orgValidacionErrors"
                            @input="$v.certificacion.organismoValidacion.$touch()"
                            @blur="$v.certificacion.organismoValidacion.$touch()"
                            counter="500"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5 && anioConsulta > 2021">
                        <v-text-field
                            v-model="primerAlcance"
                            label="Primer Alcance"
                            :error-messages="primerAlcanceErrors"
                            @input="$v.primerAlcance.$touch()"
                            @blur="$v.primerAlcance.$touch()"
                            counter="250"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5 && anioConsulta > 2021">
                        <v-text-field
                            v-model="segundoAlcance"
                            label="Segundo Alcance"
                            :error-messages="segundoAlcanceErrors"
                            @input="$v.segundoAlcance.$touch()"
                            @blur="$v.segundoAlcance.$touch()"
                            counter="250"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5 && anioConsulta > 2021">
                        <v-text-field
                            v-model="tercerAlcance"
                            label="Tercer Alcance"
                            :error-messages="tercerAlcanceErrors"
                            @input="$v.tercerAlcance.$touch()"
                            @blur="$v.tercerAlcance.$touch()"
                            counter="250"
                            required>
                        </v-text-field>
                    </v-col>                   
                    <v-col cols="12">
                        <v-text-field
                            v-model="normativa"
                            label="Nombre de Reconocimiento"
                            :error-messages="normativaErrors"
                            @input="$v.normativa.$touch()"
                            @blur="$v.normativa.$touch()"
                            counter="300"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5 && anioConsulta < 2022">
                        <v-textarea
                            v-model="inventario.otrosDetalles"
                            :error-messages="otrosErrors"
                            @input="$v.inventario.otrosDetalles.$touch()"
                            @blur="$v.inventario.otrosDetalles.$touch()"
                            counter="500"
                            label="Otros detalles">
                        </v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de Cuantificación"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fechaCuantificacion" 
                                locale="es-CR" 
                                @input="menu = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input label="Adjuntar Evidencias"
                            accept=".png, .jpeg, .jpg"
                            v-model="evidencias"
                            show-size     
                            multiple                           
                            clearable
                            :error-messages="evidenciasErrors"             
                            @change="$v.evidencias.$touch()"
                            @blur="$v.evidencias.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5 && anioConsulta > 2021">
                        <v-file-input label="Otros"
                            accept=".png, .jpeg, .jpg"
                            v-model="evidenciaOtros"
                            show-size     
                            multiple                           
                            clearable
                            :error-messages="evidenciaOtrosErrors"             
                            @change="$v.evidenciaOtros.$touch()"
                            @blur="$v.evidenciaOtros.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarEstrella">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, decimal, maxLength } from "vuelidate/lib/validators";

const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * (1024 * 1024);
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        fechaMoment () {
            return this.fechaCuantificacion ? moment(this.fechaCuantificacion).format('DD/MM/YYYY') : '';
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        emisionesErrors() {
            const errors = []
            if (!this.$v.inventario.emisionCo2.$dirty) return errors            
            !this.$v.inventario.emisionCo2.required && errors.push('Las emisiones de CO2 son requeridas.')
            !this.$v.inventario.emisionCo2.decimal && errors.push('Solo debe digitar números.')
            return errors
        },
        orgValidacionErrors() {
            const errors = []
            if (!this.$v.certificacion.organismoValidacion.$dirty) return errors            
            !this.$v.certificacion.organismoValidacion.required && errors.push('El organismo de validación es requerido.')
            !this.$v.certificacion.organismoValidacion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        normativaErrors() {
            const errors = []
            if (!this.$v.normativa.$dirty) return errors            
            !this.$v.normativa.required && errors.push('Campo requerido.')
            !this.$v.normativa.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        primerAlcanceErrors() {
            const errors = []
            if (!this.$v.primerAlcance.$dirty) return errors            
            !this.$v.primerAlcance.required && errors.push('El alcance es requerido.')
            !this.$v.primerAlcance.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        segundoAlcanceErrors() {
            const errors = []
            if (!this.$v.segundoAlcance.$dirty) return errors            
            !this.$v.segundoAlcance.required && errors.push('El alcance es requerido.')
            !this.$v.segundoAlcance.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        tercerAlcanceErrors() {
            const errors = []
            if (!this.$v.tercerAlcance.$dirty) return errors            
            !this.$v.tercerAlcance.required && errors.push('El alcance es requerido.')
            !this.$v.tercerAlcance.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        otrosErrors() {
            const errors = []
            if (!this.$v.inventario.otrosDetalles.$dirty) return errors            
            !this.$v.inventario.otrosDetalles.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        evidenciasErrors () {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('La evidencia es requerida.')
            !this.$v.evidencias.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        },
        evidenciaOtrosErrors () {
            const errors = []
            if (!this.$v.evidenciaOtros.$dirty) return errors            
            !this.$v.evidenciaOtros.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    props: [
        'tipo',
        'titulo',
    ],
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        inventario: { emisionCo2: '', otrosDetalles: '' },
        certificacion: { organismoValidacion: '', },
        fechaCuantificacion: new Date().toISOString().substr(0, 10),
        normativa: '',
        primerAlcance: '',
        segundoAlcance: '',
        tercerAlcance: '',
        evidencias: [],
        evidenciaOtros: [],
    }),
    validations: {
        inventario: {
            emisionCo2: { required: requiredIf(function() { return this.tipo === 5}), decimal },
            otrosDetalles: { maxLength: maxLength(500) }
        },
        certificacion: { organismoValidacion: { maxLength: maxLength(500), required: requiredIf(function() { return this.tipo === 6}) }, },
        evidencias: { required, maxFileSize },
        evidenciaOtros: { maxFileSize: requiredIf(function(){
            return this.anioConsulta > 2021 && this.tipo === 5;
        })},
        normativa: { required, maxLength: maxLength(300) },
        primerAlcance: { maxLength: maxLength(250), required: requiredIf(function(){
            return this.anioConsulta > 2021 && this.tipo === 5;
        })},
        segundoAlcance: { maxLength: maxLength(250), required: requiredIf(function(){
            return this.anioConsulta > 2021 && this.tipo === 5;
        })},
        tercerAlcance: { maxLength: maxLength(250), required: requiredIf(function(){
            return this.anioConsulta > 2021 && this.tipo === 5;
        })},
    },
    methods: {
        cancelar() {
            this.certificacion = {};
            this.inventario = {};
            this.normativa = '';
            this.fechaCuantificacion = new Date().toISOString().substr(0, 10);
            this.evidencias = [];
            this.dialog = false;
            this.loading = false;
            this.$v.inventario.$reset();
            this.$v.certificacion.$reset();
            this.$v.evidencias.$reset();
            this.$v.evidenciaOtros.$reset();
            this.$v.normativa.$reset();
            this.$v.primerAlcance.$reset();
            this.$v.segundoAlcance.$reset();
            this.$v.tercerAlcance.$reset();
            this.primerAlcance = '';
            this.segundoAlcance = '';
            this.tercerAlcance = '';
            this.evidenciaOtros = [];
        },
        agregarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                if (this.tipo === 5)
                    this.agregaInventarioGei();
                else
                    this.agregaCertificacionCarbono();
            }
        },
        agregaInventarioGei() {
            let formData = new FormData();
            this.evidencias.forEach(file => {
                formData.append('archivos', file);
            });
            this.evidenciaOtros.forEach(file2 => {
                formData.append('evidenciaOtros', file2);
            });
            formData.append("fecha", this.fechaCuantificacion);
            formData.append("idInstalacion", this.idInstalacion);
            formData.append("idUsuario", this.idUsuario);
            formData.append("normativa", this.normativa);
            formData.append("inventarioS", JSON.stringify(this.inventario));
            formData.append("primerAlcance", this.primerAlcance);
            formData.append("segundoAlcance", this.segundoAlcance);
            formData.append("tercerAlcance", this.tercerAlcance);
            axios.post("/estrellas/agregaInventarioGei", formData)
            .then(response => {
                this.$store.commit('historialEstrella', response.data)
                this.efectivo('Emisión de GEI agregada');
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                if (error.request && error.request.status === 415) {
                  this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                  this.loading = false;
                } else {
                 this.erroneo(error, 'la emisión de GEI');
                }
              }
            });
        },
        agregaCertificacionCarbono() {
            let formData = new FormData();
            this.evidencias.forEach(file => {
                formData.append('archivos', file);
            });
            formData.append("fecha", this.fechaCuantificacion);
            formData.append("idInstalacion", this.idInstalacion);
            formData.append("idUsuario", this.idUsuario);
            formData.append("normativa", this.normativa);
            formData.append("certificadoS", JSON.stringify(this.certificacion));
            axios.post("/estrellas/agregaCertificadoCarbono", formData)
            .then(response => {
                this.$store.commit('historialEstrella', response.data)
                this.efectivo('Certificación de Carbono Neutralidad agregada');
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                if (error.request && error.request.status === 415) {
                  this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                  this.loading = false;
                } else {
                 this.erroneo(error, 'la certificación de carbono neutralidad');
                }
              }
            });
        },
        efectivo(str) {
            this.$emit("recargaTabla");
            this.$store.dispatch("noti", { mensaje: str, color: "success" });
            this.cancelar();
        }, 
        erroneo(error, str) {
            console.log("Error al agregar " + str + ' ' + error);
            this.$store.dispatch("noti", { mensaje: "Ocurrió un error no se agregó " + str, color: "error" });
            this.cancelar();
        },
    }
};
</script>
